import ApiService from '../common/api.service'
import decrypt from '../common/decrypt'

class UserReportService {
  getAllTransactionInfo(data) {
    return ApiService.query('/all/transection/info', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  exportAllTransactionInfo(data) {
    return ApiService.query('export/all/transection/info', data).then(response => Promise.resolve(response.data), error => Promise.reject(error))
  }

  exportHistory(data) {
    return ApiService.get('all/export/history').then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getAllArchiveTransactionInfo(data) {
    return ApiService.query('/all/archive-transection/info', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getJoiningIncomeInfo(data) {
    return ApiService.query('/joining/income/info', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getWalletBalanceSummary(data) {
    return ApiService.query('/wallet/summary', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getSummaryInfo(data) {
    return ApiService.query('/summary/info', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  exportSummaryInfo(data) {
    return ApiService.query('export/summary/info', data).then(response => Promise.resolve(response.data), error => Promise.reject(error))
  }

  getTotalTransectionByDate(data) {
    return ApiService.query('/transection/sale/count', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getReloadCount(data) {
    return ApiService.query('/reload/count', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getDebitNoteCount(data) {
    return ApiService.query('/debit/count', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getTransferCount(data) {
    return ApiService.query('/transfer/count', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }
}

export default new UserReportService()
